import React from 'react'
import PropTypes from 'prop-types'
import { Button as MAButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

const GreenButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    backgroundColor: green[700],
    '&:hover': {
      backgroundColor: green[800],
    },
  },
}))(Button)

function Button({ type, ...props }) {
  function renderButton() {
    switch (type) {
      case 'green':
        return <GreenButton {...props} />
      default:
        return <MAButton {...props} />
    }
  }
  return <>{renderButton()}</>
}

Button.propTypes = {
  type: PropTypes.oneOf(['green']),
}

export default Button
