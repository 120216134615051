import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { getPost } from '@services/postservice'
import LoadingSkeleton from '@objects/loadingSkeleton'
import AnswerThread from '@components/answerthread'

import PostDetailCard from '@objects/cards/postdetailcard'
import useSnackbar from '@hooks/useSnackbar'

export const frontmatter = {
  title: 'Beiträge',
}

const useStyles = makeStyles((theme) => ({
  postCard: {
    marginTop: theme.spacing(6),
  },
}))

function PostPage({ articleid }) {
  const classes = useStyles()
  const isMountedRef = useRef(null)
  const [currentArticle, setCurrentArticle] = useState({})
  const [loading, setLoading] = useState(true)

  const { toggleSnackbar } = useSnackbar()

  useEffect(() => {
    isMountedRef.current = true
    getPostWithLoading()
    return () => {
      isMountedRef.current = false
    }
  }, [])

  function getPostWithLoading() {
    if (!isMountedRef.current) return
    setLoading(true)
    getPost(articleid).then((data) => {
      if (!data.apiError) {
        if (isMountedRef.current) {
          setCurrentArticle(data)
          setLoading(false)
        }
      } else {
        toggleSnackbar(
          `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
          'error'
        )
      }
    })
  }

  function renderAnswers() {
    return currentArticle.answers?.map((answer, i) => (
      <Grid key={i} item xs={12} md={12}>
        <AnswerThread answer={answer} fetchPost={getPostWithLoading} />
      </Grid>
    ))
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={11}>
        <LoadingSkeleton loading={loading} number={3}>
          <PostDetailCard
            className={classes.postCard}
            type="article"
            {...currentArticle}
            onActionClick={() => getPostWithLoading()}
          />
          {renderAnswers()}
        </LoadingSkeleton>
      </Grid>
    </Grid>
  )
}

PostPage.propTypes = {
  articleid: PropTypes.string,
  pageContext: PropTypes.object,
}

export default PostPage
