import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import {
  amber,
  grey,
  green,
  red,
  purple,
  lightBlue,
} from '@material-ui/core/colors'
import moment from 'moment'
import copy from 'copy-to-clipboard'

import { useIntl, FormattedMessage } from 'react-intl'
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Chip,
} from '@material-ui/core'

import {
  approvePost,
  declinePost,
  writeAnswer,
  writeComment,
  editAnswer,
  editComment,
  deleteAnswer,
  deleteComment,
} from '@services/postservice'
import EditorIconRow from '@objects/editoriconrow'
import Button from '@objects/button'
import Copy from '@objects/copy'
import ContextMenu from '@objects/contextmenu/'
import useDialog from '@hooks/useDialog'
import useSnackbar from '@hooks/useSnackbar'

const useStyles = makeStyles((theme) => ({
  card: ({
    isExpertAnswer,
    editorRejected,
    isExpertComment,
    userApproved,
    isDraft,
  }) => ({
    width: '100%',
    '&.comment': {
      borderRadius: 0,
    },
    backgroundColor: (() => {
      if (!userApproved) return grey['100']
      if (isDraft) return amber['50']
      if (editorRejected) return red['50']
      if (isExpertAnswer || isExpertComment) return purple['50']
    })(),
  }),
  toolbar: ({
    type,
    isExpertAnswer,
    isExpertComment,
    isDraft,
    userApproved,
  }) => ({
    backgroundColor: (() => {
      if (userApproved) {
        switch (type) {
          case 'article':
            return theme.palette.primary.main
          case 'answer':
            if (isDraft) return amber[400]
            return isExpertAnswer ? purple['800'] : lightBlue['500']
          default:
            if (isDraft) return amber[100]
            return isExpertAnswer || isExpertComment
              ? purple['50']
              : theme.palette.white
        }
      }
      return grey[200]
    })(),
    color: (() => {
      if (!userApproved) {
        return theme.palette.text.primary
      } else if (type === 'article' || (isExpertAnswer && !isDraft)) {
        return theme.palette.primary.contrastText
      }
      return theme.palette.text.primary
    })(),
    padding: theme.spacing(3),
    '&.rejected': {
      backgroundColor: red[type === 'comment' ? '50' : '800'],
      color: theme.palette.primary.contrastText,
    },
  }),
  editorbutton: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
  publishDraftButton: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  editDraftButton: {
    color: theme.palette.getContrastText(amber[500]),
    backgroundColor: amber[500],
    '&:hover': {
      backgroundColor: amber[700],
    },
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  info: ({ isDraft }) => ({
    fontSize: '14px',
    color: ({ type, isExpertAnswer, userApproved }) => {
      if (!userApproved) {
        return theme.palette.text.primary
      } else if (type === 'article' || (isExpertAnswer && !isDraft)) {
        return theme.palette.grey.main
      }
      return theme.palette.grey.dark
    },
  }),
  hasBeenEdited: {
    color: 'black',
    fontSize: '14px',
    fontStyle: 'italic',
    backgroundColor: 'lightgrey',
    width: 'fit-content',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  cardactions: {
    padding: theme.spacing(3),
    justifyContent: 'flex-end',
  },
  chip: {
    marginTop: theme.spacing(6),
    marginRight: theme.spacing(3),
  },
}))

function PostDetailCard({
  children,
  className,
  id,
  parentId,
  title,
  text,
  userName,
  userEmail,
  type,
  topics,
  timestamp,
  editorApproved,
  editorRejected,
  onActionClick,
  markReason,
  rejectReason,
  isExpertAnswer,
  isExpertComment,
  isPublished,
  isDeleted,
  isLast,
  userApproved,
  isDraft,
  isEdited,
  editReason,
  lastEditedTimestamp,
}) {
  const classes = useStyles({
    type,
    editorRejected,
    isExpertAnswer,
    isExpertComment,
    userApproved,
    isDraft,
  })
  const dial = useDialog()

  const ContextMenuCommonProps = {
    id: id,
    userName: userName,
    userEmail: userEmail,
    isExpert: isExpertAnswer || isExpertComment,
    type: type,
    mailto: `subject=${title?.replace(/<\/?[^>]+>/gi, '')}&body=${text
      ?.replace(/&nbsp;/gi, ' ')
      .replace(/<\/?[^>]+>/gi, '')}`,
    mark: !!markReason,
    onRemoveMark: onActionClick,
    onMark: onActionClick,
    onCopy: onCopyClick,
    onBann: () => {},
    onDecline:
      editorApproved && !editorRejected
        ? () => dial.openDialog('decline', { onSubmit: onDecline })
        : null,
  }
  const { toggleSnackbar } = useSnackbar()
  const intl = useIntl()

  function onCopyClick() {
    copy(text, { format: 'text/html' })
  }

  function onApproveClick() {
    approvePost(id, type).then((data) => {
      if (!data.apiError) {
        if (onActionClick) onActionClick()
      } else {
        toggleSnackbar(
          `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
          'error'
        )
      }
    })
  }

  function onDecline({ reason, bann, bannreason }) {
    declinePost(id, reason, bann, bannreason, type).then((data) => {
      if (!data.apiError) {
        if (onActionClick) onActionClick()
      } else {
        toggleSnackbar(
          `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
          'error'
        )
      }
    })
  }

  function onEditEntry({ title, message, isDraft, shouldDelete, editReason }) {
    if (shouldDelete) {
      deleteEntry()
    } else {
      editEntry({
        title: title,
        message: message,
        isDraft: isDraft,
        editReason: editReason,
      })
    }
  }

  function deleteEntry() {
    if (type === 'answer') {
      deleteAnswer(id).then((data) => {
        if (!data.apiError) {
          if (onActionClick) onActionClick()
        } else {
          toggleSnackbar(
            `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
            'error'
          )
        }
      })
    } else {
      deleteComment(id).then((data) => {
        if (!data.apiError) {
          if (onActionClick) onActionClick()
        } else {
          toggleSnackbar(
            `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
            'error'
          )
        }
      })
    }
  }

  function editEntry({ title, message, isDraft, editReason }) {
    if (type === 'answer') {
      editAnswer(id, title, message, isDraft, editReason).then((data) => {
        if (!data.apiError) {
          if (!isDraft) showPublishedMsg()
          if (onActionClick) onActionClick()
        } else {
          toggleSnackbar(
            `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
            'error'
          )
        }
      })
    } else {
      editComment(id, message, isDraft, editReason).then((data) => {
        if (!data.apiError) {
          if (!isDraft) showPublishedMsg()
          if (onActionClick) onActionClick()
        } else {
          toggleSnackbar(
            `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
            'error'
          )
        }
      })
    }
  }

  function onWriteNewEntry({ title, message, isDraft }) {
    if (type === 'article') {
      writeAnswer(id, title, message, isDraft).then((data) => {
        if (!data.apiError) {
          if (!isDraft) showPublishedMsg()
          if (onActionClick) onActionClick()
        } else {
          toggleSnackbar(
            `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
            'error'
          )
        }
      })
    } else {
      writeComment(type === 'answer' ? id : parentId, message, isDraft).then(
        (data) => {
          if (!data.apiError) {
            if (!isDraft) showPublishedMsg()
            if (onActionClick) onActionClick()
          } else {
            toggleSnackbar(
              `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
              'error'
            )
          }
        }
      )
    }
  }

  function onPublishClick() {
    editEntry({ title: title, message: text, isDraft: false })
  }

  function onEditClick() {
    if (type === 'answer') {
      dial.openDialog('answer', {
        onSubmit: onEditEntry,
        currentTitle: title,
        currentMsg: text,
        currentIsDraft: true,
        currentIsPublished: !isDraft,
      })
    } else {
      dial.openDialog('comment', {
        onSubmit: onEditEntry,
        currentMsg: text,
        currentIsDraft: true,
        currentIsPublished: !isDraft,
      })
    }
  }

  function onNewEntryClick() {
    if (type === 'article') {
      dial.openDialog('answer', { onSubmit: onWriteNewEntry })
    } else {
      dial.openDialog('comment', { onSubmit: onWriteNewEntry })
    }
  }

  function renderTopics() {
    return topics?.map((topic, i) => (
      <Chip key={i} className={classes.chip} label={topic} />
    ))
  }

  function showPublishedMsg() {
    toggleSnackbar(
      intl.formatMessage({
        id: 'dialog.entry.published',
      }),
      'success'
    )
  }

  console.log('log editReason', editReason)

  return (
    <Box position="relative" className={className}>
      <Card
        elevation={type === 'comment' ? 0 : 3}
        className={classNames(classes.card, type)}
      >
        <Box
          className={classNames(classes.toolbar, {
            rejected: editorRejected,
          })}
        >
          {!!title && <Typography variant="h5">{title}</Typography>}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column">
              <Typography className={classes.info}>{userName}</Typography>
              <Typography className={classes.info}>{userEmail}</Typography>
            </Box>

            <Box className={classes.infoWrapper}>
              {isEdited && (
                <Typography className={classes.hasBeenEdited}>
                  Nachträglich bearbeitet am{' '}
                  {!!lastEditedTimestamp &&
                    moment(lastEditedTimestamp)
                      .locale('de')
                      .format('DD.MM.YYYY HH:mm')}
                </Typography>
              )}
              {isEdited && !!editReason && (
                <Typography className={classes.hasBeenEdited}>
                  Grund der Änderung: {editReason}
                </Typography>
              )}

              <Typography className={classes.info}>
                {moment(timestamp).locale('de').format('DD.MM.YYYY HH:mm')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <CardContent>
          <Copy html={text} />
          {renderTopics()}
        </CardContent>
        <CardActions className={classes.cardactions}>
          <EditorIconRow
            marked={!!markReason}
            markedreason={markReason}
            approved={editorApproved}
            rejected={!!editorRejected}
            rejectedreason={rejectReason}
            notpublished={!isPublished}
            deleted={isDeleted}
          />
          {isDraft && (
            <>
              <Button
                className={classes.editDraftButton}
                variant="contained"
                size="large"
                onClick={onEditClick}
              >
                <FormattedMessage id="button.edit" />
              </Button>
              <Button
                className={classes.publishDraftButton}
                variant="contained"
                size="large"
                onClick={onPublishClick}
              >
                <FormattedMessage id="button.publish" />
              </Button>
            </>
          )}
          {!isDraft && (isExpertAnswer || isExpertComment) && (
            <Button
              className={classes.editDraftButton}
              variant="contained"
              size="large"
              onClick={onEditClick}
            >
              <FormattedMessage id="button.edit" />
            </Button>
          )}
          {!editorApproved && !editorRejected && !isDraft && (
            <>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onApproveClick}
              >
                <FormattedMessage id="button.accept" />
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={() =>
                  dial.openDialog('decline', { onSubmit: onDecline })
                }
              >
                <FormattedMessage id="button.decline" />
              </Button>
            </>
          )}
          {((type === 'comment' && isLast) ||
            (editorApproved && !isDraft && (type === 'article' || isLast))) && (
            <Button
              className={classes.editorbutton}
              variant="contained"
              size="large"
              onClick={onNewEntryClick}
            >
              <FormattedMessage
                id={
                  type === 'article'
                    ? 'button.writeanswer'
                    : 'button.writecomment'
                }
              />
            </Button>
          )}
          <ContextMenu {...ContextMenuCommonProps} />
        </CardActions>
        {children}
      </Card>
    </Box>
  )
}

PostDetailCard.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  id: PropTypes.number,
  parentId: PropTypes.number,
  type: PropTypes.oneOf(['article', 'answer', 'comment']),
  onActionClick: PropTypes.func,
  title: PropTypes.string,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  topics: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string,
  timestamp: PropTypes.string,
  numberOfLikes: PropTypes.number,
  numberOfUserAnswers: PropTypes.number,
  editorApproved: PropTypes.bool,
  editorRejected: PropTypes.bool,
  markReason: PropTypes.string,
  rejectReason: PropTypes.string,
  isExpertAnswer: PropTypes.bool,
  isExpertComment: PropTypes.bool,
  isPublished: PropTypes.bool,
  isDeleted: PropTypes.bool,
  isLast: PropTypes.bool,
  userApproved: PropTypes.bool,
  isDraft: PropTypes.bool,
  editReason: PropTypes.string,
  lastEditedTimestamp: PropTypes.string,
}

export default PostDetailCard
