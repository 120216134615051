import React from 'react'
import PropTypes from 'prop-types'
import { red, amber, deepPurple, teal } from '@material-ui/core/colors'
import {
  QuestionAnswerRounded,
  BookmarkRounded,
  WarningRounded,
  PublishRounded,
  DeleteForeverRounded,
  NewReleasesRounded,
} from '@material-ui/icons/'
import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  const iconBase = {
    backgroundColor: theme.palette.grey.light,
    borderRadius: '50%',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: '34px',
    marginRight: theme.spacing(2),
  }

  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    newpost: {
      ...iconBase,
      color: teal['500'],
    },
    newanswer: {
      ...iconBase,
      color: deepPurple['500'],
    },
    marked: {
      ...iconBase,
      color: theme.palette.primary.main,
    },
    rejected: {
      ...iconBase,
      color: red['500'],
    },
    notpublished: {
      ...iconBase,
      color: amber['500'],
    },
    deleted: {
      ...iconBase,
      color: red['500'],
    },
  }
})

function EditorIconRow({
  newanswer,
  marked,
  markedreason,
  approved,
  rejected,
  rejectedreason,
  notpublished,
  deleted,
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {!approved && !rejected && (
        <Tooltip arrow title="Neu - weder abgelehnt, noch akzeptiert">
          <NewReleasesRounded className={classes.newpost} />
        </Tooltip>
      )}
      {newanswer && (
        <Tooltip arrow title="Neue Antworten/Kommentare">
          <QuestionAnswerRounded className={classes.newanswer} />
        </Tooltip>
      )}
      {marked && (
        <Tooltip arrow title={`Markiert: ${markedreason}`}>
          <BookmarkRounded className={classes.marked} />
        </Tooltip>
      )}
      {rejected && (
        <Tooltip arrow title={`Abgelehnt: ${rejectedreason}`}>
          <WarningRounded className={classes.rejected} />
        </Tooltip>
      )}
      {notpublished && (
        <Tooltip arrow title="Noch nicht veröffentlich">
          <PublishRounded className={classes.notpublished} />
        </Tooltip>
      )}
      {deleted && (
        <Tooltip arrow title="Gelöscht">
          <DeleteForeverRounded className={classes.deleted} />
        </Tooltip>
      )}
    </div>
  )
}

EditorIconRow.propTypes = {
  newanswer: PropTypes.bool,
  marked: PropTypes.bool,
  markedreason: PropTypes.string,
  approved: PropTypes.bool,
  rejected: PropTypes.bool,
  rejectedreason: PropTypes.string,
  notpublished: PropTypes.bool,
  deleted: PropTypes.bool,
}

export default EditorIconRow
