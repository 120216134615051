import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Divider } from '@material-ui/core'

import PostDetailCard from '@objects/cards/postdetailcard'

export const frontmatter = {
  title: 'Übersicht',
}

const useStyles = makeStyles((theme) => ({
  answercard: {
    marginTop: theme.spacing(16),
  },
  cardactions: {
    justifyContent: 'flex-end',
    padding: theme.spacing(3),
  },
}))

function AnswerThread({ answer, fetchPost }) {
  const classes = useStyles()
  function renderComments(comments, answerId) {
    return comments.map((comment, i) => (
      <Fragment key={i}>
        <Divider />
        <PostDetailCard
          type="comment"
          parentId={answerId}
          isLast={i === comments.length - 1}
          {...comment}
          onActionClick={() => fetchPost()}
        />
      </Fragment>
    ))
  }

  return (
    <PostDetailCard
      className={classes.answercard}
      type="answer"
      {...answer}
      isLast={!!!answer.comments?.length}
      onActionClick={() => fetchPost()}
    >
      {!!answer.comments?.length && (
        <Box>{renderComments(answer.comments, answer.id)}</Box>
      )}
    </PostDetailCard>
  )
}

AnswerThread.propTypes = {
  answer: PropTypes.object,
  fetchPost: PropTypes.func,
}

export default AnswerThread
